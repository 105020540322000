export default {
  "header-cell": "qmD",
  "align-right": "qmV",
  "header-content": "qmL caption-bold",
  "active": "qmT",
  "empty": "qmz",
  "col-12": "qma",
  "col-8": "qmH",
  "col-6": "qmO",
  "col-4": "qmm",
  "col-2": "qmp",
  "hide": "qmx"
};
