export default {
  "card-header": "qDZ",
  "counterparty-name": "qDR",
  "pan-section": "qDQ header-section body-2",
  "infobox": "qDe header-section",
  "see-card-button": "qDB",
  "card-identifier-section": "qDE",
  "card-icon": "qDd",
  "nickname": "qDI body-1",
  "pan": "qDt body-2"
};
