export default {
  "button-wrapper": "qFw",
  "btn-filter": "qFN",
  "name-wrapper": "qFv",
  "preset-name": "qFo",
  "cancel-placeholder": "qFn",
  "cancel-button": "qFi",
  "dropdown-opened": "qFA",
  "dropdown-closed": "qFY",
  "dropdown-menu": "qFh",
  "loading-item": "qFf",
  "placeholder": "qFK",
  "list-body": "qFG",
  "list-item": "qFr",
  "error-state": "qFb body-2",
  "warning": "qFy",
  "warning-icon": "qFU",
  "footer": "qFj",
  "add-icon": "qFW",
  "empty-state": "qFu"
};
