export default {
  "vat-lines": "qpv",
  "vat-line": "qpo",
  "vat-input": "qpn",
  "vat-rate": "qpi",
  "vat-option-button": "qpA",
  "disabled": "qpY",
  "vat-option-tooltip": "qph",
  "remove-label": "qpf",
  "remove-button": "qpK",
  "vat-total": "qpG",
  "vat-total-amount": "qpr",
  "add-vat-line": "qpb",
  "with-vat-lines": "qpy"
};
