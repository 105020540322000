export default {
  "modal": "qJG",
  "section": "qJr",
  "header": "qJb",
  "title": "qJy",
  "separator-title": "qJU",
  "icon": "qJj",
  "formats": "qJW",
  "columns": "qJu",
  "column-list": "qsS",
  "draggable-column": "qsc",
  "column-field": "qsq",
  "column-selector": "qsZ",
  "column-with-error": "qsR",
  "handle": "qsQ",
  "label-color": "qse",
  "disclaimer": "qsB"
};
