export default {
  "row": "qLM",
  "col": "qLP",
  "header": "qLl",
  "block": "qLX",
  "col-5": "qLC",
  "col-6": "qLk",
  "col-9": "qLJ",
  "col-12": "qLs",
  "hide": "qLF"
};
