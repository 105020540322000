export default {
  "beneficiary-details": "qTW",
  "beneficiary-country-disclaimer": "qTu",
  "beneficiary-account-types": "qzS",
  "divider": "qzc",
  "error-disclaimer": "qzq",
  "label": "qzZ",
  "edition-info-disclaimer": "qzR",
  "sticky-panel": "qzQ",
  "spinner": "qze"
};
