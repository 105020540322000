export default {
  "row": "qaA",
  "cell": "qaY",
  "empty": "qah",
  "icon": "qaf",
  "type": "qaK",
  "amount-content": "qaG",
  "cell-approve": "qar",
  "approve-content": "qab",
  "row-compact": "qay"
};
