export default {
  "transaction": "qFL",
  "transaction-details": "qFT",
  "transaction-details-amount": "qFz body-1",
  "credit": "qFa",
  "negative-amount": "qFH",
  "transaction-details-description": "qFO body-2",
  "transaction-remove-cta": "qFm",
  "avatar": "qFp mr-16",
  "spinner": "qFx"
};
