export default {
  "row": "qVn",
  "item-bulk": "qVi",
  "active": "qVA",
  "disabled": "qVY",
  "cell": "qVh body-2",
  "not-disabled": "qVf",
  "item-amount-disabled": "qVK",
  "item-amount-credit": "qVG",
  "no-padding": "qVr",
  "cell-content": "qVb",
  "centered-cell": "qVy",
  "item-counterparty-icon": "qVU mr-16",
  "avatar": "qVj",
  "item-counterparty-name": "qVW",
  "item-counterparty-name-status": "qVu",
  "disputing-chargeback": "qLS",
  "item-counterparty-name-text": "qLc",
  "item-amount": "qLq body-1",
  "item-amount-foreign": "qLZ",
  "attachment-icon": "qLR",
  "item-method": "qLQ",
  "bank-account-avatar": "qLe",
  "attachment-status-dot": "qLB",
  "attached": "qLE",
  "missing": "qLd",
  "item-attachment": "qLI",
  "fadein-item": "qLt"
};
