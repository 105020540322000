export default {
  "display-block": "qOY",
  "pay-later-tooltip": "qOh",
  "disabled": "qOf",
  "stepper": "qOK",
  "installments": "qOG",
  "flex-items": "qOr",
  "day-label": "qOb body-2",
  "installment-amount": "qOy body-1",
  "spacing": "qOU",
  "info-icon": "qOj",
  "detail": "qOW",
  "detail-value": "qOu",
  "extra": "qmS",
  "extra-value": "qmc",
  "badge": "qmq",
  "title": "qmZ",
  "link": "qmR"
};
