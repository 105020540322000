export default {
  "transfer-header": "qVR",
  "infobox": "qVQ header-section",
  "proof-section": "qVe",
  "proof-link": "qVB",
  "download-icon": "qVE",
  "cancel-button": "qVd",
  "request-refund-button": "qVI",
  "refund-requested-indicator": "qVt",
  "recall-indicator": "qVM",
  "payment-purpose": "qVP",
  "payment-purpose-label": "qVl",
  "repeat-infobox": "qVX qVQ header-section",
  "additional-infobox": "qVC qVQ header-section",
  "repeat-transfer-action": "qVk",
  "financing-not-compliant-disclaimer": "qVJ"
};
