export default {
  "wrapper": "qTZ",
  "ember-power-select-dropdown": "qTR",
  "status": "qTQ body-1",
  "error": "qTe",
  "success": "qTB",
  "type-container": "qTE",
  "type-close": "qTd",
  "date": "qTI",
  "close-icon": "qTt",
  "container": "qTM",
  "container-picto": "qTP",
  "avatar": "qTl",
  "container-picto-status": "qTX",
  "general": "qTC",
  "general-amount": "qTk",
  "unprocessed": "qTJ",
  "fx-rate": "qTs",
  "general-counterparty": "qTF body-2",
  "infobox": "qTg",
  "account-infobox": "qTD qTg",
  "initiator-infobox": "qTV qTg",
  "transaction-infobox": "qTL qTg",
  "repeat-infobox": "qTT qTg",
  "item": "qTz body-2",
  "eye-icon": "qTa",
  "repeat-transfer-action": "qTH",
  "disclaimer": "qTO",
  "start-verification-link": "qTm",
  "financing-not-compliant-disclaimer": "qTp"
};
