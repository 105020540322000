export default {
  "row": "qmW",
  "col": "qmu",
  "counterparty": "qpS",
  "header": "qpc",
  "icon": "qpq",
  "col-12": "qpZ",
  "col-8": "qpR",
  "col-6": "qpQ",
  "col-4": "qpe",
  "col-2": "qpB",
  "hide": "qpE"
};
