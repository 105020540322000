export default {
  "row": "qHK body-1",
  "active": "qHG",
  "cell": "qHr",
  "empty": "qHb",
  "ellipsis": "qHy",
  "text-secondary": "qHU",
  "cell-type": "qHj",
  "cell-reason": "qHW",
  "cell-amount": "qHu",
  "row-sidebar": "qOS",
  "animated": "qOc",
  "fadein-item": "qOq",
  "animated-cell": "qOZ"
};
