export default {
  "wrapper": "qJH",
  "section-wrapper": "qJO",
  "header": "qJm",
  "title": "qJp body-1",
  "close-icon": "qJx",
  "section": "qJw",
  "section-title": "qJN caption-bold",
  "labels": "qJv",
  "labels-title": "qJo",
  "section-checkbox": "qJn",
  "grow": "qJi scroll",
  "label": "qJA",
  "label-spacing": "qJY",
  "buttons": "qJh"
};
