export default {
  "sidebar-content": "qLG",
  "section": "qLr",
  "header-wrapper": "qLb",
  "transfer-id": "qLy caption",
  "small-padding": "qLU",
  "title": "qLj",
  "subtitle": "qLW",
  "actions": "qLu"
};
