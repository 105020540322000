/* import __COLOCATED_TEMPLATE__ from './export-form.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { FILE_GROUPS, FILE_TYPES } from 'qonto/constants/transactions-export';

export default class TransactionsExportForm extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;

  @tracked fileType = null;

  fileTypeTranslations = {
    csv: this.intl.t('transactions.modals.export.file_formats.csv'),
    csv_extended: this.intl.t('transactions.modals.export.file_formats.csv_extended'),
    excel: this.intl.t('transactions.modals.export.file_formats.excel'),
    ofx: this.intl.t('transactions.modals.export.file_formats.ofx'),
    qif: this.intl.t('transactions.modals.export.file_formats.qif'),
  };

  selectedCSVSeparator = this.args.selectedCSVSeparator;
  showFormatSettings = false;
  withAttachments = false;

  constructor() {
    super(...arguments);

    this.fileTypes = FILE_GROUPS;
    this.fileType = this.fileTypes[0];
  }

  get showCsvBasicDescription() {
    return this.selectedFileType.code === 'csv';
  }

  get fileTypeName() {
    return this.fileType.name.split('.').pop();
  }

  get selectedFileType() {
    return this.fileTypes.find(({ code }) => code === this.fileType.code);
  }

  get isCSVOptionSelected() {
    return this.selectedFileType.group === FILE_TYPES.CSV;
  }

  confirmTask = dropTask(async () => {
    let options = {
      withAttachments: this.withAttachments,
      fileType: this.selectedFileType.code,
      separator: this.selectedCSVSeparator,
    };

    await this.args.confirm(options);
  });
}
