export default {
  "filters-checkbox": "qFt mb-8",
  "csv-options-button": "qFM",
  "active": "qFP",
  "options-format-settings-wrapper": "qFl",
  "checkbox": "qFX",
  "options-format-settings": "qFC",
  "hidden": "qFk",
  "visible": "qFJ",
  "disabled": "qFs",
  "buttons": "qFF"
};
