export default {
  "sidebar-header": "qDk",
  "sidebar-header__top": "qDJ",
  "sidebar-header__body": "qDs",
  "sidebar-header__content": "qDF",
  "sidebar-header__date": "qDg",
  "infobox": "qDD",
  "infobox--link": "qDV",
  "ghost": "qDL",
  "fail": "qDT",
  "r-transaction-disclaimer": "qDz",
  "hold-disclaimer": "qDa",
  "transaction-link": "qDH body-2",
  "icon-link": "qDO"
};
