export default {
  "transfer-form": "qzD",
  "transfer-form-title": "qzV",
  "transfer-form-subtitle": "qzL",
  "form": "qzT",
  "form-footer": "qzz",
  "footer-btn": "qza",
  "header": "qzH",
  "main-settings": "qzO",
  "additional-settings": "qzm",
  "form-container": "qzp"
};
