export default {
  "modal": "qsE",
  "section": "qsd",
  "header": "qsI",
  "title": "qst",
  "icon": "qsM",
  "content": "qsP",
  "export-template-wrapper": "qsl",
  "export-template-radio": "qsX",
  "export-template-content": "qsC",
  "export-template-label": "qsk",
  "export-template-button": "qsJ",
  "export-template-list": "qss",
  "inactive": "qsF",
  "export-template-title": "qsg",
  "export-template-description": "qsD",
  "export-template-item-content": "qsV",
  "permission-disclaimer-container": "qsL",
  "export-template-footer": "qsT",
  "export-template-cta-container": "qsz",
  "upsell": "qsa",
  "upsell-section": "qsH",
  "_content": "qsO",
  "_cta": "qsm",
  "upsell-title": "qsp",
  "upsell-description": "qsx"
};
