export default {
  "fileupload": "qpV",
  "file": "qpL",
  "label": "qpT",
  "zone": "qpz",
  "error": "qpa",
  "hide": "qpH",
  "dropzone": "qpO",
  "hidden": "qpm",
  "upload-icon": "qpp",
  "dropping": "qpx",
  "bounce2": "qpw",
  "error-message": "qpN"
};
