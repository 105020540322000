export default {
  "header-cell": "qVH",
  "header-content": "qVO caption-bold",
  "active": "qVm",
  "align-right": "qVp",
  "empty": "qVx",
  "align-checkbox": "qVw",
  "col-5": "qVN",
  "col-9": "qVv",
  "emitted-at-header-tag": "qVo"
};
