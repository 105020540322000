export default {
  "header-cell": "qaT",
  "empty": "qaz",
  "header-type": "qaa",
  "header-requester": "qaH",
  "header-amount": "qaO",
  "header-approval": "qam",
  "header-content": "qap caption-bold",
  "active": "qax",
  "row-compact": "qaw",
  "row-sidebar": "qaN",
  "hidden": "qav",
  "animated": "qao",
  "fadein-item": "qan",
  "animated-cell": "qai"
};
