/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';

import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { calculateVatAmount } from 'qonto/utils/transfers';

export default class TransfersSepaDetailsComponent extends Component {
  @service featuresManager;
  @service localeManager;

  operationTypes = Object.values(SCHEDULE_OPERATION_TYPES);
  amountField = AmountField;

  get organization() {
    return this.args.sepaTransfer.get('organization');
  }

  get isInvalidAmountField() {
    if (this.args.isValidationEnabled) {
      return Boolean(this.args.sepaTransfer.validations.attrs.amount.message);
    }

    return false;
  }

  @action
  onAmountChange(amount) {
    this.args.sepaTransfer.amount = amount;
    this._updateVatAmount();
  }

  _updateVatAmount() {
    let { sepaTransfer } = this.args;
    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      sepaTransfer.vatAmount = calculateVatAmount(amount, vatRate);
    }
  }
}
