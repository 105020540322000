export default {
  "container": "qgc",
  "item": "qgq caption-bold",
  "hover": "qgZ",
  "active": "qgR",
  "name-wrapper": "qgQ",
  "name": "qge body-2",
  "count": "qgB",
  "actions-placeholder": "qgE",
  "actions": "qgd",
  "show": "qgI",
  "action-button": "qgt"
};
