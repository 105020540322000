/* import __COLOCATED_TEMPLATE__ from './country-mismatch-disclaimer.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

export default class CountryMismatchDisclaimer extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;

  get errorMessage() {
    let { code, meta } = this.args.error.detail;
    switch (code) {
      case 'country_bic_mismatch':
        return this.intl.t('transfers.errors.bic-country-mismatch', {
          bic_country: this.intl.formatCountry(meta.identifier_country),
          bank_country: this.intl.formatCountry(meta.country),
        });
      case 'country_iban_mismatch':
        return this.intl.t('transfers.errors.iban-country-mismatch', {
          iban_country: this.intl.formatCountry(meta.identifier_country),
          bank_country: this.intl.formatCountry(meta.country),
        });
    }
  }
}
