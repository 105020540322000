export default {
  "row": "qaU body-1",
  "active": "qaj",
  "cell": "qaW",
  "empty": "qau",
  "ellipsis": "qHS",
  "text-secondary": "qHc",
  "transfer-type": "qHq",
  "transfer-type__icon": "qHZ",
  "quick-actions": "qHR",
  "amount": "qHQ",
  "cell-approve": "qHe",
  "cell-type": "qHB",
  "cell-requester": "qHE",
  "account-select": "qHd",
  "row-compact": "qHI",
  "row-sidebar": "qHt",
  "cell-amount": "qHM",
  "hidden": "qHP",
  "animated": "qHl",
  "fadein-item": "qHX",
  "animated-cell": "qHC"
};
