export default {
  "header-cell": "qHL",
  "empty": "qHT",
  "header-content": "qHz caption-bold",
  "active": "qHa",
  "header-type": "qHH",
  "header-reason": "qHO",
  "header-status": "qHm",
  "header-amount": "qHp",
  "row-sidebar": "qHx",
  "hidden": "qHw",
  "animated": "qHN",
  "fadein-item": "qHv",
  "animated-cell": "qHo"
};
