export default {
  "container": "qgD",
  "header": "qgV",
  "title": "qgL caption-bold",
  "text": "qgT body-2",
  "trigger": "qgz",
  "loading-state": "qga",
  "dropzone-placeholder-wrapper": "qgH",
  "dropzone-placeholder": "qgO",
  "file-loading": "qgm"
};
