export default {
  "wrapper": "qLp",
  "title": "qLx",
  "activity-tag": "qLw",
  "select": "qLN",
  "tooltip": "qLv",
  "date-picker": "qLo",
  "internal-notes": "qLn",
  "scheduled": "qLi"
};
