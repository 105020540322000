export default {
  "custom-export-loading": "qsi",
  "title": "qsA",
  "description": "qsY",
  "section": "qsh",
  "placeholder-avatar": "qsf",
  "_with-avatar": "qsK",
  "_with-list": "qsG",
  "list-item": "qsr",
  "radio": "qsb",
  "header": "qsy",
  "header-line": "qsU"
};
