/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants, require-await */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { bool, reads } from 'macro-decorators';

import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class TransferMultiConfirm extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service organizationManager;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service errors;

  @reads('organizationManager.organization') organization;
  @reads('organization.hasMultipleActiveAccounts') hasMultipleActiveAccounts;
  @bool('args.data.multiTransfer.limitsData.isAboveLimits') isAboveLimits;

  @tracked showErrors = false;

  get isRequestMultiTransfer() {
    let modelName = this.args.data.multiTransfer.constructor.modelName;
    return modelName === 'request-multi-transfer';
  }

  get showAccount() {
    return this.hasMultipleActiveAccounts && !this.args.data.isRequest;
  }

  get date() {
    if (this.isAboveLimits) {
      return this.intl.t('requests.transfers.modals.confirm_multi_transfer.date');
    }

    return dateToken({
      date: this.args.data.multiTransfer.scheduledDate,
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.COMPACT,
    });
  }

  submitTransfersTask = dropTask(async () => {
    let { submitTransfers } = this.args.data;

    return submitTransfers(this.args.close);
  });

  submitRequestMultiTransfersTask = dropTask(async () => {
    let { submitTransfers, multiTransfer } = this.args.data;

    let { validations } = await multiTransfer.validate();
    if (!validations.isValid) {
      return;
    }
    return submitTransfers(this.args.close);
  });

  submitTask = dropTask(async () => {
    this.showErrors = true;
    try {
      if (this.isRequestMultiTransfer) {
        await this.submitRequestMultiTransfersTask.perform();
      } else {
        await this.submitTransfersTask.perform();
      }
    } catch (error) {
      this._handleError(error);
    }
  });

  _handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    if (this.errors.shouldFlash(error)) {
      this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
    }
  }

  get description() {
    if (this.args.data.isRequest) {
      return this.intl.t('requests.transfers.modals.confirm_multi_transfer.desc');
    } else {
      return this.intl.t('transfers.modals.confirm_multi_transfer.desc');
    }
  }

  get transferAmount() {
    let { multiTransfer } = this.args.data;

    // request multi transfer
    if (multiTransfer?.totalSelectedTransfersAmount) {
      let { totalSelectedTransfersAmount, totalTransfersAmountCurrency } = multiTransfer;
      return this.intl.formatMoney(totalSelectedTransfersAmount, {
        currency: totalTransfersAmountCurrency,
      });
    }

    // multi transfer
    return this.intl.formatMoney(multiTransfer?.transfersAmount);
  }

  get hasInsufficientFundsError() {
    let { multiTransfer } = this.args.data;

    return multiTransfer.confirmErrors?.some(error => {
      return error === 'biller_amount_insufficient_funds';
    });
  }

  get insufficientFundsMessage() {
    return this.organizationManager.membership.role === ROLES.MANAGER
      ? this.intl.t('transfers.errors.manager.multi_insufficient_funds')
      : this.intl.t('transfers.errors.multi_insufficient_funds');
  }
}
