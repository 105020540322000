export default {
  "row": "qmw",
  "counterparty-name": "qmN",
  "counterparty-status": "qmv",
  "amount": "qmo",
  "next-date": "qmn",
  "end-date": "qmi",
  "frequency": "qmA",
  "active": "qmY",
  "cell": "qmh body-2",
  "no-padding": "qmf",
  "cell-content": "qmK",
  "counterparty": "qmG",
  "status-avatar": "qmr",
  "beneficiary-name": "qmb",
  "empty": "qmy",
  "disabled": "qmU",
  "fadein-item": "qmj"
};
